<template>
  <div class="text-center">
    <transition name="slide-fade">
      <v-snackbar
        v-if="snackbar"
        v-model="snackbar"
        right
        :timeout="timeout"
        class="snack-small background-infinity"
      >
        <span
          class="color-text-infinity"
          v-html="rawHtmlText"
        />
        <v-btn
          color="black"
          class="mx-4"
          height="35"
          width="100"
          @click="snackbar = false"
        >
          Fechar
        </v-btn>
      </v-snackbar>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'AmbienteInfo',
  data: () => ({
    snackbar: false,
    timeout: 1000 * 60 * 24 // (24 horas)
  }),
  created() {
    if (this.exibeAlerta) this.snackbar = true;
  },
  computed: {
    ambiente() {
      return this.translate(this.$store.getters.getAmbiente);
    },
    isHomologacao() {
      return !!(this.ambiente == 'homologação');
    },
    isDesenvolvimento() {
      return !!(this.ambiente == 'desenvolvimento');
    },
    exibeAlerta() {
      return this.isHomologacao || this.isDesenvolvimento;
    },
    rawHtmlText() {
      return this.exibeAlerta
        ? `<b>Atenção: você está conectado no ambiente de ${this.ambiente}</b>.`
        : '';
    }
  },
  methods: {
    translate(ambiente) {
      if (!ambiente) return null;
      switch (ambiente.toLowerCase()) {
        case 'staging':
          return 'homologação';
        case 'local':
          return 'desenvolvimento';
        default:
          return null;
      }
    }
  }
};
</script>

<style>
.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(30px);
  opacity: 0;
}
.snack-small .v-snack__content {
  padding: 0 10px !important;
}
.snack-bg-primary {
  padding: 3px !important;
  border-radius: 50px !important;
}
.background-infinity .v-snack__content {
  animation: changeBackgroundColor 7s infinite;
}

.color-text-infinity {
  animation: changeTextColor 7s infinite;
}
@keyframes changeBackgroundColor {
  0% {
    background-color: #001f3f;
  }
  50% {
    background-color: #ff4136;
  }
  100% {
    background-color: #001f3f;
  }
}
@keyframes changeTextColor {
  0% {
    color: black;
  }
  50% {
    color: #fff;
  }
  100% {
    color: #fff;
  }
}
</style>
